<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="margin-bottom-10 top">
        <Button type="primary" @click="add">{{$t('system_role_add')}}</Button>
        <div class="search_input">
          <Input v-model="keyword"
                 @on-enter="changeKeyword"
                 @on-search="changeKeyword"
                 :placeholder="$t('system_role_search_tip')"
                 clearable
                 search
                 :enter-button="true"
          />
        </div>
      </div>

      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('system_role_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
    </div>
  </div>

</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
export default {
  name: "role",
  data(){
    return{
      columns: [
        {
          title: this.$t('system_role_table_index'),
          // key: 'index',
          type:'index'
        },
        {
          title: this.$t('system_role_table_name'),
          key: 'name',
        },
        {
          title: this.$t('system_role_table_desc'),
          key: 'description',
        },
        {
          title: this.$t('system_role_table_num'),
          key: 'data',
        },
        {
          title: this.$t('system_role_table_op'),
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.check(params.row)
                  }
                }
              }, this.$t('system_check')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, this.$t('system_edit')),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delete(params.row);
                  }
                }
              }, this.$t('system_dele'))
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      keyword: '',
    }
  },
  components:{
    DeleModal
  },
  mounted(){
    this.getRoles();
  },
  watch: {
    keyword () {
      if (!this.keyword) {
        this.changeKeyword();
      }
    }
  },
  methods:{
    changeKeyword () {
      this.page = 1;
      this.getRoles();
    },
    add(){
      this.$router.push({
        path:'/system/role-edit'
      })
    },
    check(data){
      this.$router.push({
        path:'/system/role-edit',
        query:{
          type:'check',
          name:data.name
        }
      })
    },
    edit(data){
      this.$router.push({
        path:'/system/role-edit',
        query:{
          type:'edit',
          name:data.name
        }
      })
    },
    getRoles(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword: this.keyword,
      };
      this.api.system.rolesList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getRoles();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        name:this.curDeleData.name
      }
      this.modalLoading = true;
      this.api.system.roleDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getRoles();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getRoles();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getUserList();
    },
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
